import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import ContactButton from '../components/contact_button'
import style from '../components/about.module.css'


const AboutPage = props => {
  const { data, errors } = props
  const site = data.site

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  if (!site) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }
  return (
    <Layout>
      <SEO title={'About'} description={site.headline}/>
      <Container>
        {
          site.imageOne && <Img 
            className={style.headerImage} 
            alt={`Office Building`} 
            fluid={site.imageOne.asset.fluid}  
            objectFit 
          />
        }
        <h1 className={style.headerCopy}>{site.headline}</h1>

        {
          site.imageTwo && <Img 
            className={style.headerImage} 
            alt={`Office Building`} 
            fluid={site.imageTwo.asset.fluid}  
            objectFit 
          />
        }
                


        <div className={style.awardSection}>
          <div className={style.awardSide}>
            {/* <div className={style.test}> */}
            <h1 className={style.awardHeadline}>{site.awardHeadline}</h1>
          {/* </div> */}
          </div>

          <div className={style.awardSide}>
            { site.awardList.map((e, i) => (
              e.title && <React.Fragment key={e.title+i}>
                <h1 className={isNaN(e.title)? style.awardTitle : style.yearTitle}> {e.title.toUpperCase()} </h1>
                <ul className={style.awardList}>
                  { e.awardName.map((item, z) => (
                    item && <li className={style.awardItem} key={e.title+i+z+'item'}> {item} </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
          </div>
        </div>

        <ContactButton />  

      </Container>
    </Layout>
  )
}

export default AboutPage




export const query = graphql`
  query PageAboutQuery {
    site: sanityPageAbout(_id: { regex: "/(drafts.|)pageAbout/" }) {
      headline
      imageOne {
        asset {
          _id
          fluid(maxWidth: 2560){
            ...GatsbySanityImageFluid
          }
        }
      }
      imageTwo {
        asset {
          _id
          fluid(maxWidth: 2560){
            ...GatsbySanityImageFluid
          }
        }
      }
      awardHeadline
      awardList{
        title
        awardName
      }
    }

  }
`